import { constants } from '@wsb/guac-widget-core';
import {
  FILL,
  FIT,
  INSET,
  BLUR,
  LEGACY_BLUR,
  WIDE_INSET
} from '../../common/constants/headerTreatments';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';
import imageToHeaderTreatments from '../../common/utils/imageToHeaderTreatments';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL } =
  constants.paintJobs;

const id = 'layout18';
const imageTreatments = {
  [FILL]: 'accent-overlay',
  [FIT]: 'accent-overlay',
  [INSET]: 'category-alt-solid',
  [BLUR]: 'accent-overlay',
  [LEGACY_BLUR]: 'accent-overlay',
  [WIDE_INSET]: 'category-alt-solid'
};

const headerTreatmentsConfig = {
  defaultHeaderTreatment: FILL,
  hasLegacy: true,
  heroContentItems: ['tagline', 'tagline2', 'cta'],
  nonHeroContentItems: ['phone'],
  imageTreatments,
  headerTreatments: imageToHeaderTreatments(imageTreatments),
  insetConfig: {
    borderStyles: {
      borderColor: 'highContrast',
      borderStyle: 'solid',
      borderWidth: 'large'
    },
    props: {
      vAlign: 'top'
    }
  },
  fillConfig: {
    needsContentOverlayItems: ['tagline', 'tagline2', 'cta'],
    props: {
      fixedMinHeight: 'xlarge'
    }
  }
};
const defaultPaintJob = LIGHT;

export default {
  id,
  name: 'dawn',
  packs: {
    color: '000',
    font: 'lato'
  },
  logo: {
    font: 'primary'
  },
  packCategories: {
    color: colorPackCategories.NEUTRAL
  },
  headerProperties: {
    alignmentOption: 'center'
  },
  headerTreatmentsConfig,
  hasNavWithBackground: true,
  showSlideshowTab: true,
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob,
  buttons: {
    primary: {
      fill: buttons.fills.SOLID,
      shape: buttons.shapes.SQUARE,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    secondary: {
      fill: buttons.fills.SOLID,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.PRIMARY
    },
    ...COMMON_BUTTON_CONFIG
  }
};
