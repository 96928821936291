import React from 'react';
import { UX2 } from '@wsb/guac-widget-core';
import Default from '../../../common/Themes/Default/Theme';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import { spacingVertical } from '../../../common/utils';
import { levelFilter } from '../../../common/utils/text';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import { WIDE_INSET } from '../../../common/constants/headerTreatments';
import themeConfig from '../themeConfig';

const { FULL_UNDERLINE } = sectionHrTypes;
const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highContrast',
    fontSize: 'large',
    fontWeight: 'normal',
    letterSpacing: 'normal'
  }
};

class Theme18 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme18';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        hasLogoAlign: true,
        showOverlayOpacityControls: true,
        enableVideoOverlaySlider: true,
        hasNavBackgroundToggle: true,
        useSlideshow: true,
        headerTreatmentsConfig: {
          ...defaultProps.headerTreatmentsConfig,
          ...themeConfig.headerTreatmentsConfig
        }
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal'
          }
        },
        HeadingBeta: {
          ...typographyShared1
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingEpsilon: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: '0.063em',
            textTransform: 'none',
            [':hover']: {
              color: 'highlight'
            },
            [':active']: {
              color: 'highContrast'
            }
          },
          active: {
            style: {
              fontWeight: 'normal',
              color: 'highContrast',
              paddingBottom: 'xxsmall',
              borderBottom: '1px solid',
              [':hover']: {
                color: 'highlight'
              }
            }
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: '0.063em',
            textTransform: 'none',
            [':hover']: {
              color: 'highlight'
            },
            [':active']: {
              color: 'section'
            }
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: props => {
          const { size = 'default' } = props;
          const sizes = {
            small: {
              fontSize: 'small'
            },
            default: {
              fontSize: 'medium'
            },
            large: {
              fontSize: 'mlarge'
            }
          };
          return {
            style: {
              font: 'alternate',
              fontWeight: 'bold',
              letterSpacing: '0.063em',
              textTransform: 'none',
              ...sizes[size]
            }
          };
        }
      }
    };
  }

  Heading(props) {
    const { tag, level } = props;
    const { widgetType, widgetPreset } = this.base;
    const typographyMapping = {
      2: 'HeadingEpsilon'
    };

    return super.Heading(
      this.merge(
        {
          typography: typographyMapping[levelFilter({ tag, level })],
          style: getMenuHeadingStyle(tag, widgetType, widgetPreset)
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(
      this.merge(
        {
          typography: 'HeadingEpsilon'
        },
        props
      )
    );
  }

  // Section

  Section({ children, skipDivider, ...props }) {
    const style = {
      paddingVertical: 'xlarge'
    };
    let updatedProps = this.merge({ style, children }, props);

    if (this.base.widgetType === 'FOOTER' && !skipDivider) {
      updatedProps = this.merge(
        {
          style,
          children: (
            <React.Fragment>
              <UX2.Element.Divider style={{ paddingVertical: 'medium' }} />
              { children }
            </React.Fragment>
          )
        },
        props
      );
    }

    return super.Section(updatedProps);
  }

  SectionHeading(props) {
    return super.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'left'
          },
          sectionHeadingHR: FULL_UNDERLINE
        },
        props
      )
    );
  }

  SectionSplitHeading(props) {
    return this.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'left'
          }
        },
        props
      )
    );
  }

  SectionHeadingHR(props) {
    return super.HR(
      this.merge(
        {
          style: {
            borderBottomWidth: 'xsmall',
            borderColor: 'input',
            marginBottom: 'large',
            marginTop: 'small',
            width: '100%'
          }
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'left' }, props));
  }

  // Header
  NavLinkActive(props) {
    const paddingOffset = this.parseSizedUnit(this.mapPropValue('padding', '-xxsmall'))[0];
    const marginBottom = paddingOffset - 1;
    return this.NavLink(
      this.merge(
        {
          active: true,
          style: {
            marginBottom
          }
        },
        props
      )
    );
  }

  // Hero

  Hero(props) {
    return super.Hero(
      this.merge(
        {
          style: {
            textAlign: 'center',
            ['@md']: {
              maxWidth: '100%', // Overrides default maxWidth on Desktop
              ...spacingVertical('small')
            },
            ['@xs-only']: {
              [':last-child']: {
                marginBottom: 'large'
              }
            }
          }
        },
        props
      )
    );
  }

  HeroLeft(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            border: 'none',
            borderBottomWidth: 'xsmall',
            borderColor: 'highContrast',
            paddingLeft: 0,
            width: '100%',
            ['@sm']: {
              borderColor: 'white',
              borderLeftStyle: 'solid',
              borderLeftWidth: '1px'
            },
            ['@md']: {
              borderBottom: 'none',
              borderLeftStyle: 'solid',
              borderLeftWidth: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  Tagline(props) {
    const style =
      props.treatmentName === WIDE_INSET
        ? {}
        : {
          position: 'relative',
          marginHorizontal: 'auto',
          ['@xs-only']: {
            borderBottomStyle: 'solid',
            borderBottomWidth: 'xsmall',
            borderColor: 'highContrast',
            paddingBottom: 'small'
          }
        };
    return super.Tagline(this.merge({ style }, props));
  }

  HeroText(props) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            lineHeight: '1.5',
            maxWidth: '750px',
            display: 'inline-block'
          }
        },
        props
      )
    );
  }

  HeroLeftHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: 'xsmall',
            borderColor: 'highContrast',
            paddingBottom: 'small',
            ['@md']: {
              borderBottomWidth: 0
            }
          }
        },
        props
      )
    );
  }

  HeroRight(props) {
    return this.Hero(
      this.merge(
        {
          style: {
            border: 'none',
            borderBottomWidth: 'xsmall',
            borderColor: 'highContrast',
            paddingRight: 0,
            width: '100%',
            ['@sm']: {
              borderColor: 'white',
              borderRightStyle: 'solid',
              borderRightWidth: '1px'
            },
            ['@md']: {
              borderBottom: 'none',
              borderRightStyle: 'solid',
              borderRightWidth: 'xsmall'
            }
          }
        },
        props
      )
    );
  }

  HeroRightHeading(props) {
    return this.HeroHeading(
      this.merge(
        {
          style: {
            borderBottomStyle: 'solid',
            borderBottomWidth: 'xsmall',
            borderColor: 'highContrast',
            paddingBottom: 'small',
            ['@md']: {
              borderBottomWidth: 0
            }
          }
        },
        props
      )
    );
  }

  HeaderMediaInsetImage(props) {
    return super.Image(
      this.merge(
        {
          style: {
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: 'white'
          }
        },
        props
      )
    );
  }

  HeaderMediaBlurImage(props) {
    return this.HeaderMediaInsetImage(props);
  }

  // Form elements

  sharedInputStyles = {
    typography: 'BodyAlpha'
  };

  Input(props) {
    return super.Input(
      this.merge(
        this.sharedInputStyles,
        {
          style: {
            borderColor: 'input',
            borderRadius: 'none',
            borderStyle: 'solid',
            borderWidth: 'xsmall',
            paddingHorizontal: 'small',
            paddingVertical: 'small'
          }
        },
        props
      )
    );
  }

  InputFloatLabelInput(props) {
    return super.InputFloatLabelInput(
      this.merge(
        {
          style: {
            paddingBottom: 'xsmall'
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        this.sharedInputStyles,
        {
          style: {
            left: '16px'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        this.sharedInputStyles,
        {
          style: {
            borderRadius: 'medium',
            borderStyle: 'solid',
            borderWidth: 'xsmall'
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingHorizontal: 'small',
            paddingVertical: 'small'
          }
        },
        props
      )
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...modernIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  DividerHR(props) {
    return super.HR(
      this.merge(
        {
          style: {
            borderColor: 'input'
          }
        },
        props
      )
    );
  }

  Table(props) {
    return super.Table(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(
      this.merge(
        {
          style: {
            borderRadius: '0'
          }
        },
        props
      )
    );
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }
}

export default Theme18;
